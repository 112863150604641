import React, { useEffect, useState } from 'react';
import {
  IPlayerListRes,
  PLAYER_LIST_SEARCH_QUERY,
  PLAYER_TABLE_SEARCH_QUERY,
} from '../../common/api/queries/playerlist_query';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { Button, Modal, Popover, Space, Spin, Switch } from 'antd';
import {
  IPLAYER_EDIT_POST,
  IPLAYER_KICK_POST,
} from '../../common/api/mutations/player_mutation';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { isUpdatedVar } from '../../common/apollo';
import { Link } from 'react-router-dom';

import { BsRecordCircleFill } from 'react-icons/bs';

interface TableListProps {
  data: IPlayerListRes[] | undefined;
  tableParams: {
    pagination: TablePaginationConfig;
  };
  handleTableChange: (pagination: TablePaginationConfig) => void;
  loading: boolean;
}

export const PlayerManageList: React.FC<TableListProps> = ({
  data,
  tableParams,
  handleTableChange,
  loading,
}) => {
  const { t } = useTranslation(['page']);
  const [tableList, setTableList] = useState<string[]>([]);
  const [popLoading, setPopLoading] = useState<boolean>(false);

  const isUpdated = useReactiveVar(isUpdatedVar);

  const onOpenPopHover = (visible: boolean, record: IPlayerListRes) => {
    if (visible) {
      setPopLoading(true);
      PLAYER_TABLE_SEARCH_QUERY(record.id)
        .then((res: AxiosResponse | any) => {
          if (res.data) {
            if (res.data.data.code === 1) {
              setTableList(res.data.data.data.tables);
            } else {
              setTableList([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // setTimeout(() => {
          //   setPopLoading(false);
          // }, 3000);
          setPopLoading(false);
        });
    }
  };

  const columns: ColumnsType<IPlayerListRes> = [
    {
      title: 'No.',
      dataIndex: 'id',
      fixed: 'left',
      ellipsis: true,
    },
    {
      title: String(t('Agent ID')),
      dataIndex: 'agent_id',
      ellipsis: true,
      render: (agent: string) => (
        <p>
          {/* For the meantime use the same in the future there will be change with regards of the agent id */}
          {agent === 'dowinn1' || agent === 'dowinn001'
            ? t('PhoneBet')
            : agent === 'dowinn2' || agent === 'dowinn002'
            ? t('SpeedBet')
            : t('API')}
        </p>
      ),
    },
    {
      title: String(t('User ID')),
      dataIndex: 'disp_id',
      ellipsis: true,
      render: (user: string, record: IPlayerListRes) => (
        <p className='cursor-pointer'>
          <Popover
            content={listPlayer}
            placement={'rightTop'}
            onOpenChange={(visible) => onOpenPopHover(visible, record)}
            mouseEnterDelay={0.6}
          >
            {user}
          </Popover>
        </p>
      ),
    },
    {
      title: String(t('NickName')),
      dataIndex: 'name',
      ellipsis: true,
    },
    {
      title: String(t('isOnline')),
      dataIndex: 'connected',
      ellipsis: true,
      render: (con) => (
        <p
          className={`flex items-center gap-1 ${
            con === '1' ? 'text-green-500' : ''
          } `}
        >
          <span>
            <BsRecordCircleFill />
          </span>

          {con === '1' ? t('Online') : t('Offline')}
        </p>
      ),
    },
    {
      title: String(t('Currency')),
      dataIndex: 'currency',
      ellipsis: true,
    },
    {
      title: String(t('Status')),
      dataIndex: 'block_yn',
      ellipsis: true,
      render: (block, record) => (
        <div>
          <Switch
            className='bg-gray-500'
            checked={!block}
            onClick={() => onBlockPlayer(record)}
          />
        </div>
      ),
    },
    {
      title: String(t('Last Login')),
      dataIndex: 'login_date',
      ellipsis: true,
    },
    {
      title: String(t('Amount')),
      dataIndex: 'money',
      align: 'right',
      ellipsis: true,
      render: (amount) => (
        <p className='text-right'>{amount.toLocaleString()}</p>
      ),
    },
    {
      title: String(t('Action')),
      dataIndex: '',
      key: 'x',
      align: 'center',
      ellipsis: true,
      render: (record) => (
        <Space>
          <Button
            danger
            onClick={() => onKickPlayer(record)}
            style={{ padding: '0px 5px', height: '28px' }}
          >
            {t('Kick')}
          </Button>
        </Space>
      ),
    },
  ];

  const onBlockPlayer = async (resdata: IPlayerListRes) => {
    const data = {
      id: resdata.id,
      block_yn: !resdata.block_yn,
      connected: resdata.connected,
    };

    await IPLAYER_EDIT_POST(data)
      .then((res: AxiosResponse | any) => {
        if (res.data) {
          if (res.data.data.code === 1) {
            isUpdatedVar(!isUpdated);
            alert(t('Updated'));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onKickPlayer = async (resdata: IPlayerListRes) => {
    const kickNewPayload = {
      id: resdata.id,
      agent_id: resdata.agent_id,
      user_id: resdata.disp_id,
    };

    await IPLAYER_KICK_POST(kickNewPayload)
      .then((res: AxiosResponse | any) => {
        if (res.data) {
          if (res.data.data.code === 1) {
            isUpdatedVar(!isUpdated);
            alert(t('Successfully Kicked'));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const listPlayer = (
    <div>
      {popLoading ? (
        <Spin size='small' />
      ) : tableList.length === 0 ? (
        <p>{t('No data')}</p>
      ) : (
        tableList.map((table, i) => (
          <Link key={i} to='/table-setup'>
            {table}
          </Link>
        ))
      )}
    </div>
  );

  return (
    <Table
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={data}
      size='small'
      bordered={true}
      pagination={{
        ...tableParams.pagination,
        position: ['bottomCenter'],
      }}
      loading={loading}
      onChange={handleTableChange}
      scroll={{ x: true }}
    />
  );
};
