import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useForm, useFormState } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  INewNoticePatch,
  INewScrollNoticePost,
  NEW_NEW_SCROLL_NOTICE_PATCH,
  // INewScrollNoticePatch,
  // NEW_NEW_SCROLL_NOTICE_PATCH,
  NEW_NEW_SCROLL_NOTICE_POST,
  NEW_SCROLL_NOTICE_PATCH,
  SCROLL_NOTICE_EDIT,
} from '../../../../common/api/mutations/notice_mutation';
import { isUpdatedVar, langVar } from '../../../../common/apollo';

import { Switch } from 'antd';
import { GrClose } from 'react-icons/gr';
import { ButtonCustom } from '../../../../components/button';
import { INewNoticeResponse } from '../../../../common/api/queries/notice_query';

interface IModalSetupProps {
  setOpenSetup: React.Dispatch<React.SetStateAction<boolean>>;
  rowData: INewNoticeResponse | null;
}

export const NewScrollSetupModal: React.FC<IModalSetupProps> = ({
  setOpenSetup,
  rowData,
}) => {
  const { register, getValues, handleSubmit, control } =
    useForm<INewScrollNoticePost>({
      mode: 'onChange',
    });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  // const [isDisplay, setIsDisplay] = useState<boolean>(
  //   rowData?.status === 'Y' ? true : false
  // );
  const languageVar = useReactiveVar(langVar);

  const isUpdated = useReactiveVar(isUpdatedVar);

  const clickModalClose = () => {
    setOpenSetup((current) => !current);
  };

  // const onChange = (checked: boolean) => {
  //   setIsDisplay(checked);
  // };

  const onSubmit = () => {
    const {
      application,
      title,
      kr,
      en,
      cn,
      jp,
      startDate,
      endDate,
      type,
      status,
    } = getValues();
    const dataToSend = {
      application,
      title: rowData?.title || '',
      kr,
      en,
      cn,
      jp,
      startDate: rowData?.startDate,
      endDate: rowData?.endDate,
      type,
      status,
    };
    NEW_NEW_SCROLL_NOTICE_PATCH(dataToSend)
      .then((res: AxiosResponse | any) => {
        // console.log('The updated things: ', getValues());
        if (res.data) {
          if (res.data.data.code === 0) {
            alert(t('Update Successful'));
            isUpdatedVar(!isUpdated);
            setOpenSetup(false);
          } else {
            alert(t('Update Failed'));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(`You've opened ${rowData?.title}`, rowData);
  return (
    // <div
    //   className='box-border fixed top-0 bottom-0 left-0 right-0 overflow-auto outline-0'
    //   style={{ zIndex: '910' }}
    // >
    //   <div className='fixed flex items-center justify-center w-full h-full bg-black bg-opacity-30 backdrop-blur-sm'>
    //     <div className='w-full max-w-lg p-5 m-5 bg-white border rounded-md'>
    //       <div className='flex flex-row justify-between px-6 py-3 text-lg font-medium border-b'>
    //         <div className='text-blue-500'>{t('Edit Notice')}</div>
    //         <GrClose
    //           className='mt-1 cursor-pointer'
    //           onClick={clickModalClose}
    //         />
    //       </div>
    //       <form onSubmit={handleSubmit(onSubmit)}>
    //         <div className='flex flex-col gap-4 py-4'>
    //           <div className='w-full form-group'>
    //             <div className='flex flex-row'>
    //               <div className='mb-2 text-gray-500'>{t('Language')}</div>
    //             </div>
    //             <input
    //               value={languageVar || ''}
    //               disabled
    //               type='text'
    //               className='flex-grow bg-gray-100 forminput'
    //             />
    //           </div>

    //           <div className='w-full form-group'>
    //             <div className='flex flex-row'>
    //               <div className='mb-2 text-gray-500'>{t('Title')}</div>
    //             </div>
    //             <input
    //               {...register('title')}
    //               defaultValue={rowData?.title}
    //               required
    //               disabled
    //               type='text'
    //               className='flex-grow bg-gray-100 forminput'
    //             />
    //           </div>

    //           <div className='w-full form-group'>
    //             <div className='flex flex-row'>
    //               <div className='mb-2 text-gray-500'>
    //                 {t('Content in Korean')}
    //               </div>
    //             </div>
    //             <textarea
    //               {...register('content_ko')}
    //               defaultValue={rowData?.contentKo || ''}
    //               required
    //               maxLength={200}
    //               className='forminput'
    //             />
    //           </div>
    //           <div className='w-full form-group'>
    //             <div className='flex flex-row'>
    //               <div className='mb-2 text-gray-500'>
    //                 {t('Content in English')}
    //               </div>
    //             </div>
    //             <textarea
    //               {...register('content_en')}
    //               defaultValue={rowData?.contentEn || ''}
    //               required
    //               maxLength={200}
    //               className='forminput'
    //             />
    //           </div>
    //           <div className='w-full form-group'>
    //             <div className='flex flex-row'>
    //               <div className='mb-2 text-gray-500'>
    //                 {t('Content in Chinese')}
    //               </div>
    //             </div>
    //             <textarea
    //               {...register('content_ch')}
    //               defaultValue={rowData?.contentCh || ''}
    //               required
    //               maxLength={200}
    //               className='forminput'
    //             />
    //           </div>
    //           <div className='w-full form-group'>
    //             <div className='flex flex-row'>
    //               <div className='mb-2 text-gray-500'>
    //                 {t('Content in Japanese')}
    //               </div>
    //             </div>
    //             <textarea
    //               {...register('content_jp')}
    //               defaultValue={rowData?.contentJp || ''}
    //               required
    //               maxLength={200}
    //               className='forminput'
    //             />
    //           </div>

    //           <div className='w-full form-group'>
    //             <div className='flex flex-row'>
    //               <div className='mb-2 text-gray-500'>{t('Type')}</div>
    //             </div>
    //             <input
    //               {...register('type')}
    //               defaultValue={rowData?.type}
    //               required
    //               type='text'
    //               className='flex-grow bg-gray-100 forminput'
    //             />
    //           </div>

    //           <div className='w-full form-group'>
    //             <div className='flex flex-row'>
    //               <div className='mb-2 text-gray-500'>{t('Status')}</div>
    //             </div>
    //             <input
    //               {...register('status')}
    //               defaultValue={rowData?.status}
    //               required
    //               type='text'
    //               className='flex-grow bg-gray-100 forminput'
    //             />
    //           </div>

    //           <div className='w-full'>
    //             <ButtonCustom
    //               canClick={!isSubmitting}
    //               actionText={t('Edit Notice')}
    //             />
    //           </div>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // </div>

    <div
      className='box-border fixed top-0 bottom-0 left-0 right-0 overflow-auto outline-0'
      style={{ zIndex: 910 }}
    >
      <div className='fixed flex items-center justify-center w-full h-full bg-black bg-opacity-30 backdrop-blur-sm'>
        <div className='w-auto max-w-2xl p-6 m-5 bg-white border rounded-md'>
          <div className='flex justify-between px-6 py-4 text-lg font-medium border-b'>
            <div className='text-blue-500'>{t('Edit Notice')}</div>
            <GrClose className='cursor-pointer' onClick={clickModalClose} />
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='grid grid-cols-2 gap-6 py-6'>
              <div className='form-group'>
                <label className='mb-2 text-gray-500'>{t('Language')}</label>
                <input
                  value={languageVar || ''}
                  disabled
                  type='text'
                  className='w-full bg-gray-100 forminput'
                />
              </div>
              <div className='form-group'>
                <label className='mb-2 text-gray-500'>{t('Application')}</label>
                <input
                  {...register('application', { required: true })}
                  defaultValue={rowData?.application || ''}
                  required
                  type='text'
                  className='w-full bg-gray-100 forminput'
                />
              </div>

              <div className='col-span-2 form-group'>
                <label className='mb-2 text-gray-500'>{t('Title')}</label>
                <input
                  {...register('title')}
                  defaultValue={rowData?.title}
                  required
                  disabled
                  type='text'
                  className='w-full bg-gray-100 forminput'
                />
              </div>

              <div className='col-span-2 form-group'>
                <label className='mb-2 text-gray-500'>
                  {t('Content in Korean')}
                </label>
                <textarea
                  {...register('kr')}
                  defaultValue={rowData?.kr || ''}
                  required
                  maxLength={200}
                  className='w-full forminput'
                />
              </div>

              <div className='col-span-2 form-group'>
                <label className='mb-2 text-gray-500'>
                  {t('Content in English')}
                </label>
                <textarea
                  {...register('en')}
                  defaultValue={rowData?.en || ''}
                  required
                  maxLength={200}
                  className='w-full forminput'
                />
              </div>

              <div className='col-span-2 form-group'>
                <label className='mb-2 text-gray-500'>
                  {t('Content in Chinese')}
                </label>
                <textarea
                  {...register('cn')}
                  defaultValue={rowData?.cn || ''}
                  required
                  maxLength={200}
                  className='w-full forminput'
                />
              </div>

              <div className='col-span-2 form-group'>
                <label className='mb-2 text-gray-500'>
                  {t('Content in Japanese')}
                </label>
                <textarea
                  {...register('jp')}
                  defaultValue={rowData?.jp || ''}
                  required
                  maxLength={200}
                  className='w-full forminput'
                />
              </div>

              <div className='form-group'>
                <label className='mb-2 text-gray-500'>{t('Type')}</label>
                {/* <input
                  {...register('type')}
                  defaultValue={rowData?.type}
                  required
                  type='text'
                  className='w-full bg-gray-100 forminput'
                /> */}
                <select
                  {...register('type')}
                  defaultValue={rowData?.type}
                  className='w-full bg-gray-100 forminput'
                  required
                >
                  <option value='N'>{t('Normal')}</option>
                  <option value='A'>{t('Alert')}</option>
                </select>
              </div>

              <div className='form-group'>
                <label className='mb-2 text-gray-500'>{t('Status')}</label>
                {/* <input
                  {...register('status')}
                  defaultValue={rowData?.status}
                  required
                  type='text'
                  className='w-full bg-gray-100 forminput'
                /> */}

                <select
                  {...register('status')}
                  defaultValue={rowData?.status}
                  required
                  className='w-full bg-gray-100 forminput'
                >
                  <option value='1'>{t('Display')}</option>
                  <option value='0'>{t('No Display')}</option>
                </select>
              </div>

              <div className='col-span-2'>
                <ButtonCustom
                  canClick={!isSubmitting}
                  actionText={t('Edit Notice')}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewScrollSetupModal;
