import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useForm, useFormState } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
  INewNoticePost,
  // INewScrollNoticePatch,
  INewScrollNoticePost,
  NEW_NEW_SCROLL_NOTICE_POST,
  // NEW_NEW_SCROLL_NOTICE_PATCH,
  NEW_SCROLL_NOTICE_POST,
} from '../../../../common/api/mutations/notice_mutation';
import { isUpdatedVar, langVar } from '../../../../common/apollo';

import { GrClose } from 'react-icons/gr';
import { ButtonCustom } from '../../../../components/button';
import { INewNoticeResponse } from '../../../../common/api/queries/notice_query';

// interface IModalRegProps {
//   setOpenReg: React.Dispatch<React.SetStateAction<boolean>>;
// }

// export const NewScrollRegModal: React.FC<IModalRegProps> = ({ setOpenReg }) => {
//   const { register, getValues, handleSubmit, control } =
//     useForm<INewNoticePost>({
//       mode: 'onChange',
//     });
//   const { isSubmitting } = useFormState({ control });
//   const { t } = useTranslation(['page']);
//   const isUpdated = useReactiveVar(isUpdatedVar);

//   const clickModalClose = () => {
//     setOpenReg((current) => !current);
//   };

//   const onSubmit = () => {
//     const { lang, data } = getValues();
//     const dataToSend = {
//       lang: lang,
//       data: data,
//     };

//     NEW_SCROLL_NOTICE_POST(dataToSend)
//       .then((res: AxiosResponse | any) => {
//         if (res.data) {
//           alert(t('Registration Success'));
//           isUpdatedVar(!isUpdated);
//           setOpenReg(false);
//         } else if (res.response.data.error.status === 409) {
//           alert(res.response.data.error.message);
//         } else {
//           alert(t('Registration Failed'));
//           setOpenReg(false);
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   return (
//     <div
//       className='box-border fixed top-0 bottom-0 left-0 right-0 overflow-auto outline-0'
//       style={{ zIndex: '910' }}
//     >
//       <div className='fixed flex items-center justify-center w-full h-full bg-black bg-opacity-30 backdrop-blur-sm'>
//         <div className='w-full max-w-lg p-5 m-5 bg-white border rounded-md'>
//           <div className='flex flex-row justify-between px-6 py-3 text-lg font-medium border-b'>
//             <div className='text-blue-500'>{t('Create Notice')}</div>
//             <GrClose
//               className='mt-1 cursor-pointer'
//               onClick={clickModalClose}
//             />
//           </div>
//           <form onSubmit={handleSubmit(onSubmit)}>
//             <div className='flex flex-col gap-4 py-4'>
//               <div className='w-full form-group'>
//                 <div className='flex flex-row'>
//                   <div className='mb-2 text-gray-500'>{t('Currency')}</div>
//                 </div>
//                 <select {...register('lang')} className='forminput'>
//                   <option value='KO'>KO</option>
//                   <option value='CN'>CN</option>
//                   <option value='EN'>EN</option>
//                   <option value='JP'>JP</option>
//                 </select>
//               </div>
//               <div className='w-full form-group'>
//                 <div className='flex flex-row'>
//                   <div className='mb-2 text-gray-500'>{t('Data')}</div>
//                 </div>
//                 <textarea
//                   {...register('data')}
//                   style={{ resize: 'none' }}
//                   required
//                   maxLength={200}
//                   className='forminput'
//                   onKeyDown={(e) => {
//                     if (e.key === 'Enter') {
//                       e.preventDefault();
//                     }
//                   }}
//                 />
//               </div>
//               <div className='w-full'>
//                 <ButtonCustom
//                   canClick={!isSubmitting}
//                   actionText={t('Create Notice')}
//                 />
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

interface IModalRegProps {
  setOpenReg: React.Dispatch<React.SetStateAction<boolean>>;
  rowData: INewNoticeResponse | null;
}

export const NewScrollRegModal: React.FC<IModalRegProps> = ({
  setOpenReg,
  rowData,
}) => {
  const { register, getValues, handleSubmit, control } =
    useForm<INewScrollNoticePost>({
      mode: 'onChange',
    });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const isUpdated = useReactiveVar(isUpdatedVar);
  const languageVar = useReactiveVar(langVar);
  const clickModalClose = () => {
    setOpenReg((current) => !current);
  };

  const onSubmit = () => {
    const {
      application,
      title,
      kr,
      en,
      cn,
      jp,
      startDate,
      endDate,
      type,
      status,
    } = getValues();

    const dataToSend = {
      application,
      title,
      kr,
      en,
      cn,
      jp,
      // startDate: rowData?.startDate,
      // endDate: rowData?.endDate,
      startDate, //null value
      endDate, //null value
      type,
      status,
    };

    NEW_NEW_SCROLL_NOTICE_POST(dataToSend)
      .then((res: AxiosResponse | any) => {
        if (res.data) {
          alert(t('Notice successfully created'));
          isUpdatedVar(!isUpdated);
          setOpenReg(false);
        } else if (res.data.data.code === 0) {
        } else {
          alert(t('Something went wrong'));
          setOpenReg(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(rowData);

  return (
    <div
      className='box-border fixed top-0 bottom-0 left-0 right-0 overflow-auto outline-0'
      style={{ zIndex: '910' }}
    >
      <div className='fixed flex items-center justify-center w-full h-full bg-black bg-opacity-30 backdrop-blur-sm'>
        <div className='w-full max-w-lg p-5 m-5 bg-white border rounded-md'>
          <div className='flex flex-row justify-between px-6 py-3 text-lg font-medium border-b'>
            <div className='text-blue-500'>{t('Create Notice')}</div>
            <GrClose
              className='mt-1 cursor-pointer'
              onClick={clickModalClose}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-4 py-4'>
              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>{t('Application')}</div>
                </div>
                <input
                  {...register('application')}
                  required
                  className='forminput'
                />
              </div>
              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>{t('Title')}</div>
                </div>
                <input {...register('title')} required className='forminput' />
              </div>
              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>
                    {t('Content in Korean')}
                  </div>
                </div>
                <textarea
                  {...register('kr')}
                  required
                  maxLength={200}
                  className='forminput'
                />
              </div>
              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>
                    {t('Content in English')}
                  </div>
                </div>
                <textarea
                  {...register('en')}
                  required
                  maxLength={200}
                  className='forminput'
                />
              </div>
              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>
                    {t('Content in Chinese')}
                  </div>
                </div>
                <textarea
                  {...register('cn')}
                  required
                  maxLength={200}
                  className='forminput'
                />
              </div>
              <div className='w-full form-group'>
                <div className='flex flex-row'>
                  <div className='mb-2 text-gray-500'>
                    {t('Content in Japanese')}
                  </div>
                </div>
                <textarea
                  {...register('jp')}
                  required
                  maxLength={200}
                  className='forminput'
                />
              </div>
              <div className='grid grid-cols-2 gap-6'>
                <div className='w-full form-group'>
                  <div className='flex flex-row'>
                    <div className='mb-2 text-gray-500'>{t('Type')}</div>
                  </div>
                  {/* <input {...register('type')} required className='forminput' /> */}
                  <select {...register('type')} required className='forminput'>
                    <option value='N'>{t('Normal')}</option>
                    <option value='A'>{t('Alert')}</option>
                  </select>
                </div>
                <div className='w-full form-group'>
                  <div className='flex flex-row'>
                    <div className='mb-2 text-gray-500'>{t('Status')}</div>
                  </div>
                  <select
                    {...register('status')}
                    required
                    className='forminput'
                  >
                    <option value='1'>{t('Display')}</option>
                    <option value='0'>{t('No Display')}</option>
                  </select>
                  {/* <input {...register('status')} required className='forminput' /> */}
                </div>
              </div>
              <div className='w-full'>
                <ButtonCustom
                  canClick={!isSubmitting}
                  actionText={t('Create Notice')}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
