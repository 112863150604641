import { useReactiveVar } from '@apollo/client';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import {
  ITableResponse,
  TABLES_GET_QUERY,
} from '../../../common/api/queries/table_query';
import {
  isUpdatedVar,
  tableData,
  settingTableList,
} from '../../../common/apollo';
import { TableSetupModal } from './modal/tableSetupModal';
import { useTranslation } from 'react-i18next';
import { TableRegModal } from './modal/tableRegModal';
import { Button, Popover, Tag } from 'antd';
import { BiEdit } from 'react-icons/bi';
import { TABLE_MULTIPLE_SETUP_UPDATE } from '../../../common/api/mutations/tableSetup_mutation';
import { TableStatusInfo } from './modal/tableStatusInfo';

export const TableSetupList = () => {
  const { t } = useTranslation(['page']);
  const [modal, setModal] = useState(false);
  const [modalReg, setModalReg] = useState(false);
  const [modalTableInfo, setModalTableInfo] = useState(false);
  const [popOpen, setPopOpen] = useState(false);
  const [tableStateVal, setTableStateVal] = useState<string>('');

  const data = useReactiveVar(settingTableList);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const [limitArr, setLimitArr] = useState<string[]>([]);

  useReactiveVar(tableData);
  const onClickModal = (data: ITableResponse) => {
    setModal(!modal);
    tableData(data);
  };

  const onClickModalReg = () => {
    setModalReg(!modalReg);
  };

  const onTableInfoModal = (data: ITableResponse) => {
    setModalTableInfo(!modalTableInfo);
    tableData(data);
  };

  const toggleOpen = (newOpen: boolean) => {
    setPopOpen(newOpen);
  };

  const handleTableState = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTableStateVal(e.target.value);
  };

  const columnHelper = createColumnHelper<ITableResponse>();
  const columns = [
    columnHelper.accessor('category_code', {
      header: String(t('Junket Name')),
    }),
    columnHelper.accessor('table_code', {
      header: String(t('Table Number')),
    }),
    columnHelper.accessor('stat', {
      header: String(t('Table State')),
    }),
    columnHelper.accessor('ulimit_id', {
      header: String(t('User Table Limit')),
    }),
    columnHelper.accessor('limit_id', {
      header: String(t('Table Limit')),
    }),
    columnHelper.accessor('limit_type', {
      header: String(t('Limit Type')),
    }),
    columnHelper.accessor('type', {
      header: String(t('Table Type')),
    }),
    columnHelper.accessor('timer', {
      header: String(t('Timer Settings')),
    }),
    columnHelper.accessor('free_range1', {
      header: String(t('Free Range')),
    }),
    columnHelper.accessor('bal_bet', {
      header: String(t('SuperSpeed Bet')),
    }),
    columnHelper.accessor('robot_bet_min', {
      header: String(t('Robot Bet')),
    }),
    columnHelper.accessor('rolling_cut', {
      header: String(t('Rolling Cut')),
    }),
    columnHelper.accessor('need_confirm', {
      header: String(t('Need Confirm')),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  useEffect(() => {
    setModal(false);
    setModalReg(false);
  }, [isUpdated]);

  const limitChoiceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (limitArr?.includes(e.currentTarget.value)) {
      let splicedArr = limitArr?.filter((elem) => {
        return elem !== e.currentTarget.value;
      });
      setLimitArr(splicedArr);
    } else {
      setLimitArr([...(limitArr || []), String(e.currentTarget.value)]);
    }
  };

  const selectAll = () => {
    const checkboxes = document.getElementsByName(
      'limitCheckBox'
    ) as NodeListOf<HTMLInputElement>;
    const selectAllElem = document.getElementsByName(
      'limitSelectAll'
    ) as NodeListOf<HTMLInputElement>;
    let limitIdArr: string[] = [];
    checkboxes.forEach((checkbox) => {
      if (selectAllElem[0].checked) {
        checkbox.checked = true;
        limitIdArr.push(checkbox.value);
      } else {
        checkbox.checked = false;
        setLimitArr([]);
      }
    });
    if (selectAllElem[0].checked) {
      setLimitArr(limitIdArr);
    }
  };

  const onClickPagination = (topage: string) => {
    const actions: { [key: string]: () => void } = {
      FP: () => {
        table.setPageIndex(0);
      },
      PP: () => {
        table.previousPage();
      },
      NP: () => {
        table.nextPage();
      },
      LP: () => {
        table.setPageIndex(table.getPageCount() - 1);
      },
      NULL: () => {},
    };

    const action = actions[topage];
    if (action) {
      action();
      setLimitArr([]);
    }
  };

  const updateState = () => {
    const data = {
      table_ids: limitArr,
      table_stat: tableStateVal,
    };

    if (tableStateVal === '') {
      alert('Please select status');
    } else if (limitArr.length < 1) {
      alert('Please select to table');
    } else {
      TABLE_MULTIPLE_SETUP_UPDATE(data)
        .then((res: AxiosResponse | any) => {
          if (res.data.data.code === 1) {
            alert(t('Registration Success'));
            isUpdatedVar(!isUpdated);
            setCustomIndex(table.getState().pagination.pageIndex);
            onClickPagination('NULL');
            toggleOpen(false);
          } else {
            alert('Registration Failed');
            toggleOpen(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // To remain the current page location
  const [customIndex, setCustomIndex] = useState<number>(0);
  const [state, setState] = React.useState(table.initialState);
  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange: setState,
  }));

  useEffect(() => {
    if (customIndex > 0) {
      setState((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          pageIndex: customIndex,
        },
      }));
    }
    setCustomIndex(0);
  }, [state.pagination]);

  return (
    <div>
      <div>
        <div className='mt-5 border-l border-r rounded-md shadow-lg'>
          <div className='flex flex-row items-center justify-between border-b'>
            <div className='px-6 py-3'>
              <div className='text-lg font-semibold text-gray-500'>
                {t('Table List')}
              </div>
              <div className='text-gray-400'>{t('Inquiry & Settings')}</div>
            </div>
            <div className='flex gap-4 px-6 py-3'>
              <Popover
                content={
                  <div>
                    <select
                      style={{ width: '100%' }}
                      value={tableStateVal}
                      onChange={handleTableState}
                    >
                      <option value=''>{t('Select')}</option>
                      <option value='C'>{t('Closed')}</option>
                      <option value='O'>{t('Open')}</option>
                      <option value='H'>{t('Hide')}</option>
                      <option value='M'>{t('Maintenance')}</option>
                    </select>
                    <div className='flex justify-between mt-4'>
                      <Button onClick={() => updateState()}>Submit</Button>
                      <Button onClick={() => toggleOpen(false)}>Cancel</Button>
                    </div>
                  </div>
                }
                title={t('Table State')}
                trigger='click'
                placement='leftTop'
                open={popOpen}
                onOpenChange={toggleOpen}
              >
                <button className='flex items-center justify-center w-full h-10 p-4 text-lg text-white bg-gray-500 rounded'>
                  <BiEdit />
                </button>
              </Popover>
              <button
                className='text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-gray-500 hover:bg-gray-600'
                onClick={() => onClickModalReg()}
              >
                {t('Create Table')}
              </button>
            </div>
          </div>
          <div className='flex flex-col bg-white'>
            <div className='overflow-x-auto'>
              <div className='min-w-full'>
                <table className='w-full'>
                  <thead className='border-b bg-[#245c81]'>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id} className=''>
                        <th
                          style={{ cursor: 'default' }}
                          className='px-2 py-2 '
                        >
                          <label>
                            <input
                              type='checkbox'
                              name='limitSelectAll'
                              checked={limitArr.length < 1 ? false : true}
                              onChange={() => selectAll()}
                            />
                          </label>
                        </th>
                        {headerGroup.headers.map((header) => {
                          return (
                            <th
                              key={header.id}
                              className='px-2 py-2 text-sm font-bold text-left text-white whitespace-nowrap'
                            >
                              <div className='text-center'>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                              </div>
                            </th>
                          );
                        })}
                        <th
                          style={{ cursor: 'default' }}
                          className='px-2 py-2 text-sm font-bold text-center text-white'
                        >
                          {t('Edit')}
                        </th>
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row, index) => (
                      <tr
                        key={row.id}
                        className={`bg-white border-b transition duration-300 ease-in-out hover:bg-gray-300 whitespace-nowrap`}
                      >
                        <td className='px-4 py-2'>
                          <label>
                            <input
                              type='checkbox'
                              name='limitCheckBox'
                              checked={
                                limitArr?.includes(row.original.id)
                                  ? true
                                  : false
                              }
                              value={row.original.id}
                              onChange={(e) => limitChoiceHandler(e)}
                            />
                          </label>
                        </td>
                        {row.getVisibleCells().map((cell) => {
                          if (
                            cell.column.columnDef.header === t('Table Number')
                          ) {
                            return (
                              <td
                                key={cell.id}
                                className='px-4 py-2 text-xs text-left text-gray-900 cursor-pointer select-none font-base'
                              >
                                <p
                                  onClick={() => onTableInfoModal(row.original)}
                                  className='underline'
                                >
                                  {row.original.table_code}
                                </p>
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Table State')
                          ) {
                            return (
                              <td key={cell.id} className='text-xs text-center'>
                                <p>
                                  {row.original.stat === 'O'
                                    ? 'Open'
                                    : row.original.stat === 'C'
                                    ? 'Close'
                                    : row.original.stat === 'H'
                                    ? 'Hide'
                                    : 'Maintenance'}
                                </p>
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header ===
                            t('User Table Limit')
                          ) {
                            return (
                              <td key={cell.id} className='text-xs text-center'>
                                {cell.row.original.ulimit_id.toLocaleString()}
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Table Limit')
                          ) {
                            return (
                              <td key={cell.id} className='text-xs text-center'>
                                {cell.row.original.limit_id.toLocaleString()}
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Limit Type')
                          ) {
                            return (
                              <td key={cell.id} className='text-xs text-center'>
                                <p>
                                  {row.original.limit_type === 'T'
                                    ? 'Sum'
                                    : 'Separate'}
                                </p>
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Table Type')
                          ) {
                            return (
                              <td key={cell.id} className='text-xs text-center'>
                                <p>
                                  {row.original.type === 'S'
                                    ? 'Speed'
                                    : 'Phone'}
                                </p>
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Timer Settings')
                          ) {
                            return (
                              <td key={cell.id} className='text-xs text-center'>
                                <p>{row.original.timer.toLocaleString()}</p>
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Free Range')
                          ) {
                            return (
                              <td
                                key={`cell_${cell.id}`}
                                className='px-4 py-2 text-xs text-center'
                              >
                                {
                                  <div
                                    className={`grid place-items-center border rounded-md p-1 text-xs ${
                                      cell.row.original.free_range_yn === true
                                        ? 'border-green-600 text-green-600'
                                        : 'border-red-600 text-red-600'
                                    } `}
                                  >
                                    {cell.row.original.free_range1}/
                                    {cell.row.original.free_range2}
                                  </div>
                                }
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('SuperSpeed Bet')
                          ) {
                            const balPlayerBet: number | string = cell.row
                              .original.bal_pbet
                              ? cell.row.original.bal_pbet.toLocaleString()
                              : 'none';
                            const balBankerBet: number | string = cell.row
                              .original.bal_bbet
                              ? cell.row.original.bal_bbet.toLocaleString()
                              : 'none';
                            return (
                              <td
                                key={`cell_${cell.id}`}
                                className='px-4 py-2 text-xs text-center'
                              >
                                {
                                  <div>
                                    {balPlayerBet} / {balBankerBet}
                                  </div>
                                }
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Robot Bet')
                          ) {
                            return (
                              <td
                                key={`cell_${cell.id}`}
                                className='px-4 py-2 text-xs text-center'
                              >
                                {
                                  <div>
                                    {cell.row.original.robot_bet_min
                                      ? cell.row.original.robot_bet_min.toLocaleString()
                                      : 'none'}
                                  </div>
                                }
                              </td>
                            );
                          } else if (
                            cell.column.columnDef.header === t('Need Confirm')
                          ) {
                            return (
                              <td
                                key={`cell_${cell.id}`}
                                className='px-4 py-2 font-medium text-center'
                              >
                                {
                                  <div>
                                    {cell.row.original.need_confirm ? 'O' : 'X'}
                                  </div>
                                }
                              </td>
                            );
                          } else {
                            return (
                              <td
                                key={cell.id}
                                className='px-4 py-2 text-xs text-left text-gray-900 font-base'
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          }
                        })}
                        <td className='flex items-center justify-center p-2'>
                          <div className='p-2 rounded cursor-pointer hover:bg-gray-100'>
                            <AiFillEdit
                              className='text-base'
                              onClick={() => onClickModal(row.original)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='flex flex-wrap items-center justify-center gap-2 py-4'>
            <button
              className='p-1 border rounded'
              onClick={() => onClickPagination('FP')}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='p-1 border rounded'
              onClick={() => onClickPagination('PP')}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='p-1 border rounded'
              onClick={() => onClickPagination('NP')}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='p-1 border rounded'
              onClick={() => onClickPagination('LP')}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
            <span className='flex items-center gap-1'>
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <span className='flex items-center gap-1'>
              | Go to page:
              <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                  setLimitArr([]);
                }}
                className='w-16 p-1 border rounded'
              />
            </span>
            <select
              className='p-1 border rounded'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
                setLimitArr([]);
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        {modal ? <TableSetupModal setModal={setModal} /> : null}
        {modalReg ? <TableRegModal setModalReg={setModalReg} /> : null}
        {modalTableInfo ? (
          <TableStatusInfo setOpenTableInfo={setModalTableInfo} />
        ) : null}
      </div>
    </div>
  );
};
