import { AxiosResponse } from 'axios';
import { apiClient, apiDetailClient } from '../apiConfig';

export interface INoticePatch {
  id: number | undefined;
  name: string;
  type: string;
  status: string;
  start: number | null;
  end: number | null;
  contentKo: string;
  contentEn: string;
  contentCh: string;
  contentJp: string;
}

export interface INoticePost {
  name: string;
  type: string;
  status: string;
  start: number | null;
  end: number | null;
  contentKo: string;
  contentEn: string;
  contentCh: string;
  contentJp: string;
}

export interface INewNoticePatch {
  lang: string;
  text: string;
  disp_yn: string;
}

export interface INewNoticePost {
  lang: string;
  data: string;
}

export interface INewScrollNoticePost {
  application: string;
  title: string;
  kr: string;
  en: string;
  cn: string;
  jp: string;
  startDate?: string;
  endDate?: string;
  type: string;
  status: string;
}

export const SCROLL_NOTICE_GET = async () => {
  try {
    const response: AxiosResponse = await apiClient.get(
      '/notice/get_gs_scroll_notice'
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const SCROLL_NOTICE_EDIT = async (data: INewNoticePatch) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch(
      '/notice/update_gs_scroll_notice',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const SCROLL_NOTICE_POST = async (data: INoticePost) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post('/notice', payload);
    return response;
  } catch (err) {
    return err;
  }
};

export const SCROLL_NOTICE_PATCH = async (data: INoticePatch) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch('/notice', payload);
    return response;
  } catch (err) {
    return err;
  }
};

export const NEW_SCROLL_NOTICE_POST = async (data: INewNoticePost) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      '/notice/send_gs_notice',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const NEW_SCROLL_NOTICE_PATCH = async (data: INewNoticePatch) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch(
      '/notice/update_gs_scroll_notice',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const NEW_NEW_SCROLL_NOTICE_POST = async (
  data: INewScrollNoticePost
) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'maintenance/notice',
      payload
    );
    return response;
  } catch (err) {
    console.error('Error updating scroll notice:', err);
    throw err;
  }
};

export const NEW_NEW_SCROLL_NOTICE_PATCH = async (
  data: INewScrollNoticePost
) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch(
      'maintenance/notice',
      payload
    );
    return response;
  } catch (err) {
    console.error('Error updating scroll notice:', err);
    throw err;
  }
};
