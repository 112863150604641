import React, { useEffect, useState } from 'react';
import { langVar, routeTitleVar } from '../../../common/apollo';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { APP_VERSION_GET } from '../../../common/api/queries/appVersion_query';
import { Empty, Spin } from 'antd';
import { VERSION_POST } from '../../../common/api/mutations/version_mutation';
import { GrClose } from 'react-icons/gr';
import AppVersionModal from './appVersionModal';
import { AiFillEdit } from 'react-icons/ai';
import AppVersionEditModal from './appVersionEditModal';

function AppVersion() {
  const { t } = useTranslation(['page']);
  const selectedLang = useReactiveVar(langVar);
  const [versionData, setVersionData] = useState({ cms: '', clubhome: '' });
  const [newVersion, setNewVersion] = useState('');
  const [newName, setNewName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentEditData, setCurrentEditData] = useState({
    name: '',
    version: '',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAppVersion = async () => {
      setLoading(true);
      try {
        const res = await APP_VERSION_GET();
        //@ts-ignore res topak
        setVersionData(res.data.data.version);
      } catch (error) {
        console.error('Error fetching App version datas:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchAppVersion();

    routeTitleVar(String(t('Version Manage')));
  }, [selectedLang, t]);

  const openEditModal = (name: string, version: string) => {
    setCurrentEditData({ name, version });
    setIsEditModalOpen(true);
  };

  return (
    <div>
      <div className='mt-5 border-l border-r rounded-md shadow-lg'>
        <div className='flex flex-row items-center justify-between border-b'>
          <div className='px-6 py-3'>
            <div className='text-lg font-semibold text-gray-500'>
              {t('Version List')}
            </div>
            <div className='text-gray-400'>{t('Add & View Versions')}</div>
          </div>
          <div className='flex flex-row gap-6 px-6 py-3'>
            <button
              className='text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-gray-500 hover:bg-gray-600'
              onClick={() => setIsModalOpen(true)}
              disabled={loading}
            >
              {t('Add Version')}
            </button>
          </div>
        </div>
        <div className='overflow-x-auto'>
          {loading ? (
            <div className='flex items-center justify-center h-64'>
              <Spin size='large' />
            </div>
          ) : (
            <table className='min-w-full border border-gray-300'>
              <thead>
                <tr className='bg-[#245c81] text-white'>
                  <th className='px-4 py-2'>{t('Name')}</th>
                  <th className='px-4 py-2'>{t('Version')}</th>
                  <th className='px-4 py-2'>{t('Edit')}</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(versionData).length === 0 ? (
                  <tr>
                    <td colSpan={2} className='px-4 py-2 border-b'>
                      <div className='flex items-center justify-center h-64 ml-56'>
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description='No Data'
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  Object.entries(versionData).map(([name, version]) => (
                    <tr key={name} className='text-center border-b'>
                      <td className='px-4 py-2'>{name}</td>
                      <td className='px-4 py-2'>{version || 'N/A'}</td>
                      <td className='px-4 py-2'>
                        <AiFillEdit
                          className='mx-auto text-base cursor-pointer'
                          onClick={() => openEditModal(name, version)}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <AppVersionModal
        isModalOpen={isModalOpen}
        clickModalClose={() => setIsModalOpen(false)}
        setVersionData={setVersionData}
        newVersion={newVersion}
        setNewVersion={setNewVersion}
        newName={newName}
        setNewName={setNewName}
      />

      <AppVersionEditModal
        isEditModalOpen={isEditModalOpen}
        clickModalClose={() => setIsEditModalOpen(false)}
        currentEditData={currentEditData}
        setVersionData={setVersionData}
      />
    </div>
  );
}

export default AppVersion;
