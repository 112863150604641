import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export const APP_VERSION_GET = async () => {
  try {
    const response: AxiosResponse = await apiClient.get('maintenance/version');
    // console.log('APP VERSION:', response);
    return response;
  } catch (err) {
    return err;
  }
};
