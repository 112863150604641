import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Helmet } from 'react-helmet-async';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  myAuthority,
  routeTitleVar,
  isLoggedInVar,
  langVar,
  junketList,
  gameTableList,
} from '../common/apollo';
import { useTranslation } from 'react-i18next';
import { SESSIONSTORAGE_LANGUAGE } from '../common/constants';
import { JUNKET_GET_QUERY } from '../common/api/queries/junket_query';
import { TABLES_GET_QUERY } from '../common/api/queries/table_query';

import { BsGlobe } from 'react-icons/bs';
import { MdLogout } from 'react-icons/md';
import { FaUsersCog } from 'react-icons/fa';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import { PageNotFound } from '../pages/404';
import logoImage from '../common/assets/logo.png';
import type { MenuProps } from 'antd';
import {
  LaptopOutlined,
  ReconciliationOutlined,
  UserOutlined,
  PicCenterOutlined,
} from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import { AiOutlineBars } from 'react-icons/ai';
import { FaUser } from 'react-icons/fa';
import { GiReceiveMoney, GiToken } from 'react-icons/gi';
const { Header, Content, Sider, Footer } = Layout;
import { LandingPage } from '../pages/landingPage';
import Dropdown from 'antd/es/dropdown';
import Badge from 'antd/es/badge';

import { fixedSidebarMenu } from '../components/sidebarMenu';
import { TableSetup } from '../pages/setup/tableSetup/tableSetup';
import { GameResult } from '../pages/gameResult/gameResult';
import { RGameBet } from '../pages/betHistory/rGame/rGameBet';
import { ScGameBet } from '../pages/betHistory/scGame/scGameBet';
import { SoGameBet } from '../pages/betHistory/soGame/soGameBet';
import { WinLoss } from '../pages/winLoss/winloss';
import { UserBetLimit } from '../pages/setup/betLimit/userBetLimit/userBetLimit';
import { TableBetLimit } from '../pages/setup/betLimit/tableBetLimit/tableBetLimit';
import { UserTableLimit } from '../pages/setup/betLimit/userTableLimit/userTableLimit';
import { OldScrollNotice } from '../pages/setup/scrollNotice/scrollNotice';
import { NewScrollNotice } from '../pages/management/newScrollNotice/newScrollNotice';
import { PhoneTip } from '../pages/tipSetup/phoneTip/phoneTip';
import { SpeedTip } from '../pages/tipSetup/speedTip/speedTip';
import { CurrencySetup } from '../pages/setup/currencySetup/currencySetup';
import { ChipSetup } from '../pages/setup/chipSetup/chipSetup';
import { SaGameBet } from '../pages/betHistory/saGame/saGameBet';
import { DummyUser } from '../pages/setup/dmmyUserSetting/dmmyUserSet';
import { PlayerManage } from '../pages/playerManage/playerManage';
import MaintenanceSchedule from '../pages/management/maintenanceSchedule/maintenanceSchedule';
import AppVersion from '../pages/management/appVersion/appVersion';

const adminRoutes = [{ path: '/', element: <LandingPage /> }];

const commonRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: '/userbetlimit-setup', element: <UserBetLimit /> },
  // { path: '/usertablelimit-setup', element: <UserTableLimit /> },
  { path: '/tablebetlimit-setup', element: <TableBetLimit /> },
  { path: '/table-setup', element: <TableSetup /> },
  { path: '/currency-setup', element: <CurrencySetup /> },
  { path: '/chip-setup', element: <ChipSetup /> },
  { path: '/notice', element: <NewScrollNotice /> },
  { path: '/dummy-user', element: <DummyUser /> },
  { path: '/game-result', element: <GameResult /> },
  { path: '/game-bet-p', element: <RGameBet /> },
  { path: '/game-bet-sc', element: <ScGameBet /> },
  { path: '/game-bet-so', element: <SoGameBet /> },
  { path: '/game-bet-sa', element: <SaGameBet /> },
  // { path: '/winloss', element: <WinLoss /> },
  { path: '/phone-tip', element: <PhoneTip /> },
  { path: '/speed-tip', element: <SpeedTip /> },
  { path: '/player-manage', element: <PlayerManage /> },
  { path: '/maintenance-schedule', element: <MaintenanceSchedule /> },
  { path: '/app-version', element: <AppVersion /> },
];

export const LoggedInRouter: React.FC = () => {
  const { t, i18n } = useTranslation(['page']);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [openSidebar, setOpenSidebar] = useState(true);
  const [overlaySidebar, setOverlaySidebar] = useState(true);

  const routeTitle = useReactiveVar(routeTitleVar);
  const grantedAuthority = useReactiveVar(myAuthority);
  useReactiveVar(isLoggedInVar);
  const selectedLang = useReactiveVar(langVar);

  const navigate = useNavigate();

  const getJunketList = () => {
    JUNKET_GET_QUERY().then((res: AxiosResponse | any) => {
      if (res.data.data.code === 1) {
        junketList(res.data.data.data.list);
      }
    });
  };

  const getTableCodeList = () => {
    TABLES_GET_QUERY().then((res: AxiosResponse | any) => {
      if (res.data.data[0].code === 1) {
        gameTableList(res.data.data[0].data.list);
      }
    });
  };

  useEffect(() => {
    getJunketList();
    getTableCodeList();
  }, []);

  const grantedRoutes =
    grantedAuthority === '[ROLE_ADMIN]'
      ? [...adminRoutes, ...commonRoutes]
      : commonRoutes;

  const fixedSidebarMenu: MenuProps['items'] = [
    {
      key: `parent_설정`,
      label: t('Setting'),
      icon: React.createElement(UserOutlined),
      children: [
        { key: '/userbetlimit-setup', label: t('Limit Setting (User)') },
        // { key: '/usertablelimit-setup', label: t('Limit Setting (User Table)') },
        { key: '/tablebetlimit-setup', label: t('Limit Setting (Table)') },
        { key: '/table-setup', label: t('Table Setting') },
        { key: '/currency-setup', label: t('Currency Setup') },
        { key: '/chip-setup', label: t('Chip Setup') },

        { key: '/dummy-user', label: t('Dummy User Setting') },
      ],
    },
    {
      key: `parent_게임결과_관리`,
      label: t('Game result Management'),
      icon: React.createElement(PicCenterOutlined),
      children: [{ key: '/game-result', label: t('Game Result Change') }],
    },
    {
      key: `parent_베팅내역관리`,
      label: t('Betting history Management'),
      icon: React.createElement(LaptopOutlined),
      children: [
        { key: '/game-bet-p', label: t('Phone Game') },
        { key: '/game-bet-sc', label: t('Speed Game (Credit)') },
        { key: '/game-bet-so', label: t('Speed Game (Online)') },
        { key: '/game-bet-sa', label: t('Speed Game (Avatar)') },
      ],
    },
    // {
    //   key: `parent_윈로스관리`,
    //   label: t('Winloss Management'),
    //   icon: React.createElement(ReconciliationOutlined),
    //   children: [{ key: '/winloss', label: t('WinLoss Details') }],
    // },
    {
      key: `parent_팁_수정`,
      label: t('Tip Setup'),
      icon: React.createElement(GiToken),
      children: [
        { key: '/phone-tip', label: t('Phone Tip List') },
        { key: '/speed-tip', label: t('Speed Tip List') },
      ],
    },
    {
      key: `parent_플레이어_관리`,
      label: t('Player Management'),
      icon: React.createElement(FaUsersCog),
      children: [{ key: '/player-manage', label: t('Player Manage') }],
    },
    {
      key: `parent_Maintenance`,
      label: t('Management'),
      icon: React.createElement(HiOutlineWrenchScrewdriver),
      children: [
        { key: '/notice', label: t('Notice') },
        { key: '/maintenance-schedule', label: t('Schedule') },
        { key: '/app-version', label: t('Version Manage') },
      ],
    },
  ];

  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(e.currentTarget.value);
    langVar(String(e.currentTarget.value));
    sessionStorage.setItem(
      SESSIONSTORAGE_LANGUAGE,
      String(e.currentTarget.value)
    );
  };

  useEffect(() => {
    i18n.changeLanguage(String(selectedLang));
  }, []);

  const logout = () => {
    sessionStorage.clear();
    routeTitleVar('');
    myAuthority('');
    isLoggedInVar(false);
    navigate('/');
  };

  const logoOnClick = () => {
    routeTitleVar('');
    navigate('/');
  };

  const clickOpenSideBar = () => {
    setOpenSidebar(!openSidebar);
  };

  const onClickNavigateMypage = () => {
    routeTitleVar('My Page');
    navigate('/mypage');
  };

  useEffect(() => {
    const handleScreenResize = (
      event: MediaQueryListEvent | MediaQueryList
    ) => {
      if (event.matches) {
        setOpenSidebar(false);
        setOverlaySidebar(false);
      } else {
        setOpenSidebar(true);
        setOverlaySidebar(true);
      }
    };
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    handleScreenResize(mediaQuery);
    mediaQuery.addEventListener('change', handleScreenResize);
    return () => {
      mediaQuery.removeEventListener('change', handleScreenResize);
    };
  }, []);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <div onClick={() => onClickNavigateMypage()}>{t('My Page')}</div>,
    },
    {
      key: '2',
      label: <div onClick={() => logout()}>{t('Log Out')}</div>,
    },
  ];

  const handleNavigate = (key: string) => {
    navigate(key);
    if (!overlaySidebar) {
      setOpenSidebar(false);
    }
  };

  const languageArr = [
    <option key='한국어' value='한국어'>
      {t('한국어')}
    </option>,
    <option key='English' value='English'>
      {t('English')}
    </option>,
    <option key='中文' value='中文'>
      {t('中文')}
    </option>,
  ];

  /* Auto collapse the menu */
  // const parentMenu = [
  //   'parent_설정',
  //   'parent_게임결과_관리',
  //   'parent_베팅내역관리',
  //   'parent_윈로스관리',
  //   'parent_팁_수정',
  // ];

  // const [openKeys, setOpenKeys] = useState(['parent_설정']);

  // const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
  //   const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
  //   if (latestOpenKey && parentMenu.indexOf(latestOpenKey!) === -1) {
  //     setOpenKeys(keys);
  //   } else {
  //     setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  //   }
  // };

  return (
    <div>
      <Layout className='min-h-screen'>
        <Header
          style={{
            height: '50px',
            backgroundColor: '#355C7D',
            paddingLeft: '20px',
            paddingRight: '20px',
            zIndex: '10',
          }}
        >
          <div className='flex justify-between h-full '>
            <div className='flex flex-row'>
              <img
                src={logoImage}
                alt=''
                className='mt-2 ml-1 cursor-pointer h-7'
                onClick={logoOnClick}
              />
              <AiOutlineBars
                className='mt-3 text-2xl text-white cursor-pointer ml-7'
                onClick={clickOpenSideBar}
              />
            </div>

            <div className='flex flex-row items-center gap-2'>
              <div className='items-center text-xl text-white'>
                <BsGlobe />
              </div>
              <select
                className='text-base rounded-sm cursor-pointer w-15'
                onChange={(e) => onChangeLang(e)}
              >
                <option key={selectedLang} value={String(selectedLang)}>
                  {t(String(selectedLang))}
                </option>
                {languageArr.filter((elem) => {
                  return elem.key !== String(selectedLang);
                })}
              </select>
              <Dropdown
                menu={{ items }}
                placement='bottomRight'
                className='cursor-pointer'
              >
                <Badge className='flex'>
                  <FaUser className='text-2xl text-white' />
                </Badge>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Layout
          style={{
            position: 'relative',
          }}
        >
          <Sider
            width={openSidebar ? 210 : 0}
            style={{
              background: colorBgContainer,
              borderRightWidth: overlaySidebar ? '2px' : '0px',
              transition: '0.3s',
              zIndex: 5,
              height: !overlaySidebar ? '100%' : '100vh',
              margin: overlaySidebar ? '-50px 0 0 0' : undefined,
              padding: overlaySidebar ? '48px 0 0 0' : undefined,
              overflowX: !overlaySidebar ? 'hidden' : undefined,
              position: !overlaySidebar ? 'absolute' : undefined,
              boxShadow: !overlaySidebar ? '0px 0px 10px #888888' : undefined,
            }}
          >
            <div className='flex flex-col h-full'>
              <div
                className='flex-grow menu-scrollbar'
                style={{ overflowY: 'auto', overflowX: 'hidden' }}
              >
                <div className='mt-3 text-xl font-semibold text-gray-500 pl-7'>
                  {t('Menu')}
                </div>
                <Menu
                  // openKeys={openKeys}
                  // onOpenChange={onOpenChange}
                  onClick={({ key }) => handleNavigate(key)}
                  mode='inline'
                  style={{ height: '90%', borderRight: 0 }}
                  items={fixedSidebarMenu}
                />
              </div>
              <button
                onClick={() => logout()}
                className='sticky bottom-0 flex items-center justify-center w-full py-3 bg-white'
                style={{ boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.1)' }}
              >
                <MdLogout className='text-2xl text-black' />
              </button>
            </div>
          </Sider>
          <Layout style={{ padding: '0' }}>
            <Content
              style={{
                // padding: '10px 24px 24px 24px',
                margin: 0,
                height: '10px',
                // minHeight: 280,
                background: colorBgContainer,
                overflowY: openSidebar && !overlaySidebar ? 'hidden' : 'auto',
              }}
            >
              {openSidebar && !overlaySidebar ? (
                <div
                  className='h-full w-full bg-black absolute bg-opacity-30 z-[1] cursor-pointer'
                  onClick={clickOpenSideBar}
                ></div>
              ) : undefined}
              <div className='pt-2.5 pb-6 px-6'>
                <div className='mb-2 ml-1 text-xl font-semibold text-gray-500'>
                  {routeTitle}
                  <Helmet>
                    <title>DOWINN | Account General</title>
                  </Helmet>
                </div>
                <Routes>
                  {}
                  {grantedRoutes.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.element}
                      />
                    );
                  })}
                  <Route path='*' element={<PageNotFound />} />
                </Routes>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center', backgroundColor: 'white' }}>
              <div className='flex flex-col justify-center gap-2 sm:flex-row'>
                <div> Copyright© {new Date().getFullYear()} DOWINN Group.</div>
                <div>All rights reserved.</div>
              </div>
            </Footer>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
};
