import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { langVar, routeTitleVar } from '../../../common/apollo';
import { INoticeSearchForm } from '../../../common/api/queries/notice_query';
import { useTranslation } from 'react-i18next';
import { ButtonCustom } from '../../../components/button';

interface NewScrollNoticeSearchProps {
  onSearch: (data: INoticeSearchForm) => void;
}
export const NewScrollNoticeSearch = ({
  onSearch,
}: NewScrollNoticeSearchProps) => {
  const { t } = useTranslation(['page']);
  const selectedLang = useReactiveVar(langVar);
  const { register, getValues, handleSubmit } = useForm<INoticeSearchForm>({
    mode: 'onChange',
  });

  useReactiveVar(routeTitleVar);

  useEffect(() => {
    routeTitleVar(String(t('Notice')));
  }, [selectedLang]);

  const listType = [
    { key: '', value: '', label: t('Select') },
    { key: 'N', value: 'N', label: t('Normal') },
    { key: 'A', value: 'A', label: t('Alert') },
  ];

  const listStatus = [
    { key: '', value: '', label: t('Select') },
    { key: '1', value: '1', label: t('Display') },
    { key: '0', value: '0', label: t('No Display') },
  ];

  // const onSubmit = () => {};
  const onSubmit = (data: INoticeSearchForm) => {
    onSearch(data);
  };

  return (
    <div className='p-6 bg-white rounded-lg shadow-lg'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-wrap items-center justify-end gap-3 mb-5'>
          <div className='flex flex-row justify-between w-full gap-3 sm:w-auto'>
            <div className='w-full form-group'>
              <div className='mb-2 text-gray-500 whitespace-nowrap'>
                {t('Type')}
              </div>
              <select {...register('type')} className='forminput'>
                {listType.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className='w-full form-group'>
              <div className='mb-2 text-gray-500 whitespace-nowrap'>
                {t('Status')}
              </div>
              <select {...register('status')} className='forminput'>
                {listStatus.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='flex flex-row w-full gap-3 sm:w-auto pt-7'>
            <div className='flex'>
              <ButtonCustom canClick={true} actionText={t('Search')} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
