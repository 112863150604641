import React, { useEffect, useState } from 'react';
import { langVar, routeTitleVar } from '../../../common/apollo';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { AiFillEdit } from 'react-icons/ai';
import { AxiosResponse } from 'axios';
import {
  MAINTENANCE_SCHEDULE_GET,
  MAINTENANCE_SCHEDULE_PATCH,
} from '../../../common/api/mutations/maintenanceSchedule_mutation';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Empty, Spin } from 'antd';
import MaintenanceModal from './maintenanceModal';
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';

interface MaintenanceEntry {
  name: string;
  fromTime: Date;
  toTime: Date;
}

function MaintenanceSchedule() {
  const { t } = useTranslation(['page']);
  const selectedLang = useReactiveVar(langVar);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [maintenanceData, setMaintenanceData] = useState<MaintenanceEntry[]>(
    []
  );
  const [loading, setLoading] = useState(true);

  const convertTimestampToDate = (timestamp: string | number): Date => {
    return new Date(Number(timestamp) * 1000);
  };

  const convertDateToTimestamp = (date: Date): number => {
    return Math.floor(date.getTime() / 1000);
  };

  const getTimeDateData = () => {
    setLoading(true);
    MAINTENANCE_SCHEDULE_GET().then((res: AxiosResponse | any) => {
      const maintenance = res.data.data.maintenance;
      const data = Object.entries(maintenance).map(
        ([key, value]: [string, unknown]) => {
          const stringValue = value as string;
          const [fromTime, toTime] = stringValue.split(':');
          return {
            name: key,
            fromTime: convertTimestampToDate(fromTime),
            toTime: convertTimestampToDate(toTime),
          };
        }
      );
      setMaintenanceData(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    routeTitleVar(String(t('Schedule')));
    getTimeDateData();
  }, [selectedLang]);

  const handleDateChange = (
    index: number,
    type: 'fromTime' | 'toTime',
    date: Date | null
  ) => {
    if (date === null) return;
    const updatedData = [...maintenanceData];
    updatedData[index][type] = date;
    setMaintenanceData(updatedData);
  };

  const handleSubmit = async (rowData: MaintenanceEntry) => {
    if (rowData.fromTime > rowData.toTime) {
      alert('Start time must be earlier than End time.');
      return;
    }

    const body = {
      name: rowData.name,
      from_time: convertDateToTimestamp(rowData.fromTime),
      to_time: convertDateToTimestamp(rowData.toTime),
    };

    try {
      const response = await MAINTENANCE_SCHEDULE_PATCH(body);
      if (response.data.data.code === 0) {
        alert(`${rowData.name} maintenance schedule updated successfully!`);
      } else {
        alert(`Failed to update: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Failed to update maintenance schedule', error);
      alert('An error occurred while updating the maintenance schedule.');
    }
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'fromTime',
        header: 'Start Time',
        cell: ({ row }: any) => (
          <DatePicker
            selected={row.original.fromTime}
            onChange={(date) => handleDateChange(row.index, 'fromTime', date)}
            showTimeSelect
            timeIntervals={30}
            dateFormat='yyyy / MM / dd  h:mm aa'
            className='custom-datepicker min-w-[150px] max-w-[170px]'
          />
        ),
      },
      {
        accessorKey: 'toTime',
        header: 'End Time',
        cell: ({ row }: any) => (
          <DatePicker
            selected={row.original.toTime}
            onChange={(date) => handleDateChange(row.index, 'toTime', date)}
            showTimeSelect
            timeIntervals={30}
            dateFormat='yyyy / MM / dd  h:mm aa'
            className='custom-datepicker min-w-[150px] max-w-[170px]'
          />
        ),
      },
      {
        accessorKey: 'edit',
        header: 'Edit',
        cell: ({ row }: any) => (
          <button
            className='px-4 py-1 text-white bg-blue-500 rounded hover:bg-blue-600'
            onClick={() => handleSubmit(row.original)}
          >
            Update
          </button>
        ),
      },
    ],
    [maintenanceData]
  );

  const table = useReactTable({
    data: maintenanceData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const updateAfterNewEntry = (newEntry: string | any) => {
    const convertedEntry = {
      ...newEntry,
      fromTime: convertTimestampToDate(newEntry.from_time),
      toTime: convertTimestampToDate(newEntry.to_time),
    };

    setMaintenanceData((prevData) => [...prevData, convertedEntry]);
  };

  const handleModalClose = () => setIsModalOpen(false);

  return (
    <div className='mt-5 border-l border-r rounded-md shadow-lg'>
      {/* <div className='flex flex-row items-center justify-between border-b'>
        <div className='px-6 py-3'>
          <div className='text-lg font-semibold text-gray-500'>
            {t('Version List')}
          </div>
          <div className='text-gray-400'>{t('Add & View Versions')}</div>
        </div>
        <div className='flex flex-row gap-6 px-6 py-3'>
          <button
            className='text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-gray-500 hover:bg-gray-600'
            onClick={() => setIsModalOpen(true)}
          >
            {t('Add Schedule')}
          </button>
        </div>
      </div>
      <div className='overflow-x-auto'>
        <table className='min-w-full border border-gray-300'>
          <thead>
            <tr className='bg-[#245c81] text-white'>
              <th className='px-4 py-2'>Name</th>
              <th className='px-4 py-2'>Start Time</th>
              <th className='px-4 py-2'>End Time</th>
              <th className='px-4 py-2'>Edit Time</th>
            </tr>
          </thead>
          <tbody>
            {maintenanceData.length === 0 ? (
              <tr>
                <td colSpan={4} className='px-4 py-2 border-b'>
                  <div className='flex items-center justify-center h-64'>
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description='No Data'
                    />
                  </div>
                </td>
              </tr>
            ) : (
              maintenanceData.map((row, index) => (
                <tr key={row.name} className='text-center border-b'>
                  <td className='px-4 py-2 capitalize'>{row.name}</td>
                  <td className='px-4 py-2'>
                    <DatePicker
                      selected={row.fromTime || null}
                      onChange={(date) =>
                        handleDateChange(index, 'fromTime', date)
                      }
                      showTimeSelect
                      timeIntervals={30}
                      dateFormat='yyyy / MM / dd  h:mm aa'
                      className='custom-datepicker min-w-[150px] max-w-[170px]'
                    />
                  </td>
                  <td className='px-4 py-2'>
                    <DatePicker
                      selected={row.toTime || null}
                      onChange={(date) =>
                        handleDateChange(index, 'toTime', date)
                      }
                      showTimeSelect
                      timeIntervals={30}
                      dateFormat='yyyy / MM / dd  h:mm aa'
                      className='custom-datepicker min-w-[150px] max-w-[170px]'
                    />
                  </td>
                  <td className='px-4 py-2'>
                    <button
                      className='px-4 py-1 text-white bg-blue-500 rounded hover:bg-blue-600'
                      onClick={() => handleSubmit(row)}
                    >
                      Update
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div> */}

      <div className='flex flex-row items-center justify-between border-b'>
        <div className='px-6 py-3'>
          <div className='text-lg font-semibold text-gray-500'>
            {t('Version List')}
          </div>
          <div className='text-gray-400'>{t('Add & Edit Versions')}</div>
        </div>
        <div className='flex flex-row gap-6 px-6 py-3'>
          <button
            className='text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-gray-500 hover:bg-gray-600'
            onClick={() => setIsModalOpen(true)}
            disabled={loading}
          >
            {t('Add Schedule')}
          </button>
        </div>
      </div>

      <div className='overflow-x-auto'>
        {loading ? (
          <div className='flex items-center justify-center h-64'>
            <Spin size='large' />
          </div>
        ) : (
          <table className='min-w-full border border-gray-300'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className='bg-[#245c81] text-white'>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} className='px-4 py-2'>
                      {/* {header.isPlaceholder ? null : header.renderHeader()} */}
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {maintenanceData.length === 0 ? (
                <tr>
                  <td colSpan={4} className='px-4 py-2 border-b'>
                    <div className='flex items-center justify-center h-64'>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t('No Data')}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id} className='text-center border-b'>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className='px-4 py-2'>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        )}
      </div>

      {isModalOpen ? (
        <MaintenanceModal
          onClose={handleModalClose}
          onUpdateTable={updateAfterNewEntry}
        />
      ) : null}
    </div>
  );
}

export default MaintenanceSchedule;
