import { error } from 'console';
import { apiClient } from '../apiConfig';

import { AxiosResponse } from 'axios';

export interface IMaintenanceSchedule {
  name: string;
  from_time: number;
  to_time: number;
}

export const MAINTENANCE_SCHEDULE_PATCH = async (
  data: IMaintenanceSchedule
): Promise<AxiosResponse<any>> => {
  try {
    const response = await apiClient.patch('maintenance/isMaintenance', data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const MAINTENANCE_SCHEDULE_GET = async (): Promise<
  AxiosResponse<any>
> => {
  try {
    const response = await apiClient.get('maintenance/isMaintenance');
    return response;
  } catch (err) {
    throw err;
  }
};

export const SCHEDULE_POST = async (data: IMaintenanceSchedule) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'maintenance/isMaintenance',
      payload
    );
    return response;
  } catch (error) {
    console.error('Error adding new Schedule', error);
    throw error;
  }
};
