import React, { useEffect, useState } from 'react';
import {
  IPlayerListRes,
  IPlayerSearchForm,
  PLAYER_LIST_SEARCH_QUERY,
} from '../../common/api/queries/playerlist_query';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { PlayerManageList } from './playerManageList';
import { ButtonCustom } from '../../components/button';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { isUpdatedVar, langVar, routeTitleVar } from '../../common/apollo';

export interface TableParams {
  pagination: TablePaginationConfig;
}

export const PlayerSearch = () => {
  const { register, getValues, handleSubmit } = useForm<IPlayerSearchForm>({
    mode: 'onChange',
  });
  const { t } = useTranslation(['page']);

  const [agentId, setAgentId] = useState<string>('');
  const [data, setData] = useState<IPlayerListRes[]>([]);

  const isUpdated = useReactiveVar(isUpdatedVar);
  const selectedLang = useReactiveVar(langVar);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const onSubmit = async () => {
    const { agent_id, disp_id, connected, block_yn } = getValues();

    const { current: cur_page, pageSize: unit_page } =
      tableParams.pagination || {};

    const data = {
      agent_id: agentId ? agentId : agent_id,
      disp_id: disp_id ? disp_id : null,
      connected: connected ? connected : null,
      block_yn: block_yn ? block_yn : null,
      cur_page: cur_page,
      unit_page: unit_page,
    };

    await PLAYER_LIST_SEARCH_QUERY(data)
      .then((res: AxiosResponse | any) => {
        if (res.data) {
          if (res.data.data.code === 1) {
            setData(res.data.data.data.list);
            setTableParams((prevTableParams) => ({
              ...prevTableParams,
              pagination: {
                ...prevTableParams.pagination,
                total: res.data.data.data.tot_page * Number(unit_page),
              },
            }));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    onSubmit();
  }, [
    tableParams.pagination.current,
    tableParams.pagination.pageSize,
    isUpdated,
  ]);

  useEffect(() => {
    routeTitleVar(String(t('Player Manage')));
  }, [selectedLang]);
  return (
    <>
      <div className='p-6 bg-white rounded-lg shadow-lg'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-wrap items-center justify-end gap-3 mb-5'>
            <div className='flex flex-row justify-between w-full gap-3 sm:w-auto'>
              {/* <div className='w-full form-group'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('ID')}
                </div>
                <input
                {...register('agent_id')}
                  type='text'
                  className='forminput'
                  placeholder={String(t('ID'))}
                /> 
              </div>
              */}
              <div className='w-full form-group'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Agent Type')}
                </div>
                <select {...register('agent_id')} className='forminput'>
                  <option value='A'>{t('All')}</option>
                  <option value='P'>{t('PhoneBet')}</option>
                  <option value='S'>{t('SpeedBet')}</option>
                </select>
              </div>

              <div className='w-full form-group'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Agent Id')}
                </div>
                <input
                  // {...register('agent_id')}
                  onChange={(e) => {
                    setAgentId(e.target.value);
                  }}
                  type='text'
                  className='forminput'
                  placeholder={String(t('Agent ID'))}
                />
              </div>

              <div className='w-full form-group'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('User ID')}
                </div>
                <input
                  {...register('disp_id')}
                  type='text'
                  className='forminput'
                  placeholder={String(t('User ID'))}
                />
              </div>

              <div className='w-full form-group'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Status')}
                </div>
                <select {...register('block_yn')} className='forminput'>
                  <option value=''>{t('All')}</option>
                  <option value='true'>{t('Blocked')}</option>
                  <option value='false'>{t('Allowed')}</option>
                </select>
              </div>

              <div className='w-full form-group'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Connected')}
                </div>
                <select {...register('connected')} className='forminput'>
                  <option value=''>{t('All')}</option>
                  <option value='1'>{t('Online')}</option>
                  <option value='0'>{t('Offline')}</option>
                </select>
              </div>
            </div>

            <div className='flex flex-row w-full gap-3 pt-6 sm:w-auto'>
              <div className='flex'>
                <ButtonCustom canClick={true} actionText={t('Search')} />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='mt-5 border-l border-r rounded-md shadow-lg'>
        <div className='flex justify-between p-4 rounded-md'>
          <div>
            <h1 className='text-lg font-semibold '> {t('Player list')}</h1>
            <p className='text-xs '>{t('Edit & Kick')}</p>
          </div>
        </div>
        <PlayerManageList
          data={data}
          tableParams={tableParams}
          handleTableChange={handleTableChange}
          loading={false}
        />
      </div>
    </>
  );
};
