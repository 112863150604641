import React from 'react';
import { GrClose } from 'react-icons/gr';
import { VERSION_POST } from '../../../common/api/mutations/version_mutation';

interface AppVersionModalProps {
  isModalOpen: boolean;
  clickModalClose: () => void;
  newVersion: string;
  setNewVersion: (version: string) => void;
  newName: string;
  setNewName: (name: string) => void;
  setVersionData: React.Dispatch<any>;
}

const AppVersionModal: React.FC<AppVersionModalProps> = ({
  isModalOpen,
  clickModalClose,
  setVersionData,
  newVersion,
  setNewVersion,
  newName,
  setNewName,
}) => {
  const handleAddVersion = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const data = { version: newVersion, name: newName };
      const message = await VERSION_POST(data);
      console.log(message);
      setVersionData((prev: any) => ({ ...prev, [newName]: newVersion }));
      setNewVersion('');
      setNewName('');
      clickModalClose();
    } catch (error) {
      console.error('Error adding version:', error);
    }
  };

  if (!isModalOpen) return null;

  return (
    isModalOpen && (
      <div
        className='box-border fixed top-0 bottom-0 left-0 right-0 overflow-auto outline-0'
        style={{ zIndex: 910 }}
      >
        <div className='fixed flex items-center justify-center w-full h-full bg-black bg-opacity-30 backdrop-blur-sm'>
          <div className='w-full max-w-lg p-6 m-5 bg-white border rounded-md'>
            <div className='flex justify-between px-6 py-4 text-lg font-medium border-b'>
              <div className='text-blue-500'>Add Version</div>
              <GrClose className='cursor-pointer' onClick={clickModalClose} />
            </div>

            <form onSubmit={handleAddVersion}>
              <div className='flex flex-col gap-4 p-4'>
                <input
                  type='text'
                  placeholder='Name'
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  className='p-2 border rounded'
                  required
                />
                <input
                  type='text'
                  placeholder='Version'
                  value={newVersion}
                  onChange={(e) => setNewVersion(e.target.value)}
                  className='p-2 border rounded'
                  required
                />
                <button
                  type='submit'
                  className='w-full text-white rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-blue-500 hover:bg-blue-600'
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default AppVersionModal;
