import React from 'react';
import { Link } from 'react-router-dom';
import mglass from '../common/assets/mglass.png';

export const PageNotFound = () => {
  return (
    <div className='flex flex-col items-center justify-center h-full '>
      <img
        src={mglass}
        alt='Searching illustration'
        className='w-40 h-40 mt-10 mb-10 select-none'
      />

      <h2 className='mt-10 mb-3 text-6xl font-bold text-gray-800'>
        Page Not Found
      </h2>

      <h4 className='mb-5 text-lg font-medium text-gray-600'>
        It looks like you&#39;re lost. Let us help you find your way back.
      </h4>

      <Link to='/' className='mb-8 text-lg text-blue-500 hover:underline'>
        Go back home &rarr;
      </Link>
    </div>
  );
};
