import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  INewNoticeResponse,
  NEW_NOTICE_GET_QUERY,
  NEW_SCROLL_NOTICE_GET,
} from '../../../common/api/queries/notice_query';
import { AxiosResponse } from 'axios';
import { AiFillEdit } from 'react-icons/ai';
// import { ScrollRegModal } from './modal/scrollRegModal';
import { useReactiveVar } from '@apollo/client';
import { isUpdatedVar, langVar } from '../../../common/apollo';
import { NewScrollRegModal } from './modal/newScrollRegModal';
import { NewScrollSetupModal } from './modal/newScrollSetupModal';
import { SCROLL_NOTICE_GET } from '../../../common/api/mutations/notice_mutation';
// import { ScrollSetupModal } from './modal/scrollSetupModal';
import { Empty, Spin } from 'antd';

interface NewScrollNoticeListProps {
  searchData: INewNoticeResponse[];
  filterType: string;
  filterStatus: string;
}

export const NewScrollNoticeList = ({
  searchData,
  filterType,
  filterStatus,
}: NewScrollNoticeListProps) => {
  const { t } = useTranslation(['page']);
  const [data, setData] = useState<INewNoticeResponse[]>([]);
  const [openReg, setOpenReg] = useState(false);
  const [openSetup, setOpenSetup] = useState(false);
  const [selectData, setSelectData] = useState<INewNoticeResponse | null>(null);
  const [filterApp, setFilterApp] = useState('');
  const [uniqueApplications, setUniqueApplications] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const languageVar = useReactiveVar(langVar);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const onClickModalSetup = (data: INewNoticeResponse) => {
    setOpenSetup(!openSetup);
    setSelectData(data);
  };

  const onClickModalReg = () => {
    setOpenReg(!openReg);
  };

  const getUniqueApplications = (notices: INewNoticeResponse[]) => {
    const applications: string[] = [];
    notices.forEach((notice) => {
      if (!applications.includes(notice.application)) {
        applications.push(notice.application);
      }
    });
    return applications;
  };

  const getNoticeList = () => {
    setLoading(true);
    NEW_SCROLL_NOTICE_GET()
      .then((res: AxiosResponse | any) => {
        if (res.data && res.data.data && res.data.data.notice) {
          // setData(res.data.data.notice);
          const noticeList = res.data.data.notice;
          setData(noticeList);
          // console.log('Fetched notice list:', res.data.data.notice);
          const applications = getUniqueApplications(noticeList);
          setUniqueApplications(applications);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch notice list:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getApplicationName = (app: string) => {
    switch (app) {
      case 'clubhome':
        return t('Clubhome');
      case 'cms':
        return t('CMS');
      default:
        return app;
    }
  };

  const filteredData = useMemo(() => {
    return data.filter((notice) => {
      const matchesType = filterType ? notice.type === filterType : true;
      const matchesStatus = filterStatus
        ? notice.status === filterStatus
        : true;
      const matchesApp = filterApp ? notice.application === filterApp : true;

      return matchesType && matchesStatus && matchesApp;
    });
  }, [data, filterType, filterStatus, filterApp]);

  useEffect(() => {
    getNoticeList();
  }, [languageVar, isUpdated]);

  const columnHelper = createColumnHelper<INewNoticeResponse>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('title', {
        header: String(t('Title')),
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor(
        (row) => {
          const contents = [
            row.kr || '',
            row.en || '',
            row.cn || '',
            row.jp || '',
          ];

          let displayContent = '';

          if (languageVar === '中文') {
            displayContent = row.cn || contents.find((c) => c) || '-';
          } else if (languageVar === 'English') {
            displayContent = row.en || contents.find((c) => c) || '-';
          } else if (languageVar === '한국어') {
            displayContent = row.kr || contents.find((c) => c) || '-';
          } else if (languageVar === 'jp') {
            displayContent = row.jp || contents.find((c) => c) || '-';
          } else {
            displayContent = contents.find((c) => c) || 'No content available';
          }

          return displayContent;
        },
        {
          header: String(t('Content')),
          cell: (info) => info.getValue() || '-',
        }
      ),
      columnHelper.accessor('type', {
        header: String(t('Type')),
        cell: (info) => info.getValue() || '-',
      }),
      columnHelper.accessor('status', {
        header: String(t('Status')),
        cell: (info) => info.getValue() || '-',
      }),
    ],
    [languageVar, t]
  );

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    setOpenSetup(false);
    // getNoticeList();
  }, [isUpdated]);

  return (
    <div className='mt-5 border-l border-r rounded-md shadow-lg'>
      <div className='flex flex-row items-center justify-between border-b'>
        <div className='px-6 py-3'>
          <div className='text-lg font-semibold text-gray-500'>
            {t('Notice List')}
          </div>
          <div className='text-gray-400'>{t('Inquiry & Settings')}</div>
        </div>
        <div className='flex flex-row gap-6 px-6 py-3'>
          <select
            className='p-2 bg-white border border-gray-300 rounded outline-none focus:border-blue-600'
            value={filterApp}
            onChange={(e) => setFilterApp(e.target.value)}
            disabled={loading}
          >
            <option value=''>{t('All')}</option>
            {uniqueApplications.map((app) => (
              <option key={app} value={app}>
                {getApplicationName(app)}
              </option>
            ))}
          </select>
          <button
            className='text-white w-full rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-gray-500 hover:bg-gray-600'
            onClick={() => onClickModalReg()}
            disabled={loading}
          >
            {t('Create Notice')}
          </button>
        </div>
      </div>
      <div className='flex flex-col bg-white'>
        <div className='overflow-x-auto'>
          <div className='min-w-full'>
            {loading ? (
              <div className='flex items-center justify-center h-64'>
                <Spin size='large' />
              </div>
            ) : (
              <table className='w-full'>
                <thead className='border-b bg-[#245c81]'>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className='px-2 py-2 text-sm font-bold text-left text-white'
                          style={{ minWidth: '150px', textAlign: 'center' }}
                        >
                          <div className='text-center'>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </div>
                        </th>
                      ))}
                      <th
                        style={{
                          cursor: 'default',
                          minWidth: '80px',
                          textAlign: 'center',
                        }}
                        className='px-2 py-2 text-sm font-bold text-center text-white'
                      >
                        {t('Edit')}
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.length === 0 ? (
                    <tr>
                      <td
                        colSpan={table.getHeaderGroups()[0].headers.length + 1}
                      >
                        <div className='flex items-center justify-center h-64'>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description='No Data'
                          />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className='transition duration-300 ease-in-out bg-white border-b hover:bg-gray-300'
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className='px-4 py-4 text-xs font-medium text-gray-900'
                            style={{
                              whiteSpace: 'normal',
                              wordWrap: 'break-word',
                              overflow: 'visible',
                              textAlign: 'center',
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                        <td className='flex items-center justify-center p-2'>
                          <div
                            className='p-2 cursor-pointer'
                            onClick={() => onClickModalSetup(row.original)}
                          >
                            <AiFillEdit className='text-base' />
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      {openReg ? (
        <NewScrollRegModal setOpenReg={setOpenReg} rowData={selectData} />
      ) : null}
      {openSetup ? (
        <NewScrollSetupModal setOpenSetup={setOpenSetup} rowData={selectData} />
      ) : null}
    </div>
  );
};
