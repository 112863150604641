import React, { useEffect, useState } from 'react';
import { NewScrollNoticeSearch } from './newScrollNoticeSearch';
import { NewScrollNoticeList } from './newScrollNoticeList';
import {
  INewNoticeResponse,
  INoticeSearchForm,
  NEW_SCROLL_NOTICE_GET,
} from '../../../common/api/queries/notice_query';
import { useReactiveVar } from '@apollo/client';
import { langVar } from '../../../common/apollo';

export const NewScrollNotice = () => {
  const [data, setData] = useState<INewNoticeResponse[]>([]);
  const [filterType, setFilterType] = useState<string>('');
  const [filterStatus, setFilterStatus] = useState<string>('');
  const selectedLang = useReactiveVar(langVar);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await NEW_SCROLL_NOTICE_GET();
        setData(res.data.data.notice);
      } catch (error) {
        console.error('Error fetching notices:', error);
      }
    };

    fetchData();
  }, [selectedLang]);

  const handleSearch = (searchParams: INoticeSearchForm) => {
    setFilterType(searchParams.type);
    setFilterStatus(searchParams.status);
  };
  return (
    <div>
      <NewScrollNoticeSearch onSearch={handleSearch} />
      <NewScrollNoticeList
        searchData={data}
        filterType={filterType}
        filterStatus={filterStatus}
      />
    </div>
  );
};
