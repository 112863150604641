import React, { useState } from 'react';
import { GrClose } from 'react-icons/gr';
import DatePicker from 'react-datepicker';
import { SCHEDULE_POST } from '../../../common/api/mutations/maintenanceSchedule_mutation';

interface ModalProps {
  onClose: () => void;
  onUpdateTable: any;
}

const MaintenanceModal: React.FC<ModalProps> = ({ onClose, onUpdateTable }) => {
  const [name, setName] = useState('');
  const [fromTime, setFromTime] = useState<Date | null>(null);
  const [toTime, setToTime] = useState<Date | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!name || !fromTime || !toTime) {
      alert('Please fill all fields.');
      return;
    }

    if (fromTime > toTime) {
      alert('Start time must be earlier than End time.');
      return;
    }

    const fromTimeUnix = Math.floor(fromTime.getTime() / 1000);
    const toTimeUnix = Math.floor(toTime.getTime() / 1000);

    try {
      const response = await SCHEDULE_POST({
        name,
        from_time: fromTimeUnix,
        to_time: toTimeUnix,
      });
      if (response.data.data.code === 0) {
        alert('Schedule added successfully!');
        onUpdateTable({
          name,
          from_time: fromTimeUnix,
          to_time: toTimeUnix,
        });
        onClose();
      } else {
        alert(`Failed: ${response.data.data.message}`);
      }
    } catch (error) {
      console.error('Error adding schedule:', error);
      alert('An error occurred while adding the schedule.');
    }
  };

  return (
    <div
      className='box-border fixed top-0 bottom-0 left-0 right-0 overflow-auto outline-0'
      style={{ zIndex: '910' }}
    >
      <div className='fixed flex items-center justify-center w-full h-full bg-black bg-opacity-30 backdrop-blur-sm'>
        <div className='w-full max-w-lg p-5 m-5 bg-white border rounded-md'>
          <div className='flex items-center justify-between px-4 py-3 text-lg font-medium border-b'>
            <div className='text-blue-500'>Add Maintenance Schedule</div>
            <GrClose className='mt-1 ml-5 cursor-pointer' onClick={onClose} />
          </div>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col w-full gap-4 p-4'>
              <label className='-mb-3 text-sm font-bold text-gray-700 '>
                Name
              </label>
              <input
                type='text'
                className='w-full p-2 border border-gray-300 rounded'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <label className='block -mb-3 text-sm font-bold text-gray-700'>
                Start Time
              </label>
              <DatePicker
                selected={fromTime}
                onChange={(date) => setFromTime(date)}
                showTimeSelect
                dateFormat='yyyy/MM/dd h:mm aa'
                className='w-full p-2 border border-gray-300 rounded'
              />
              <label className='block -mb-3 text-sm font-bold text-gray-700'>
                End Time
              </label>
              <DatePicker
                selected={toTime}
                onChange={(date) => setToTime(date)}
                showTimeSelect
                dateFormat='yyyy/MM/dd h:mm aa'
                className='w-full p-2 border border-gray-300 rounded'
              />
              <button
                type='submit'
                className='px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceModal;
