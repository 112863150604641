import React, { useEffect, useState } from 'react';
import { GrClose } from 'react-icons/gr';
import {
  VERSION_PATCH,
  VERSION_POST,
} from '../../../common/api/mutations/version_mutation';

interface AppVersionEditModalProps {
  isEditModalOpen: boolean;
  clickModalClose: () => void;
  currentEditData: { name: string; version: string };
  setVersionData: React.Dispatch<React.SetStateAction<any>>;
}

const AppVersionEditModal: React.FC<AppVersionEditModalProps> = ({
  isEditModalOpen,
  clickModalClose,
  currentEditData,
  setVersionData,
}) => {
  const [name, setName] = useState(currentEditData.name);
  const [version, setVersion] = useState(currentEditData.version);

  useEffect(() => {
    setName(currentEditData.name);
    setVersion(currentEditData.version);
  }, [currentEditData, isEditModalOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await VERSION_PATCH({ name, version });
      setVersionData((prev: any) => ({ ...prev, [name]: version }));
      clickModalClose();
    } catch (error) {
      console.error('Error updating version:', error);
    }
  };

  if (!isEditModalOpen) return null;

  return (
    <div
      className='box-border fixed top-0 bottom-0 left-0 right-0 overflow-auto outline-0'
      style={{ zIndex: 910 }}
    >
      <div className='fixed flex items-center justify-center w-full h-full bg-black bg-opacity-30 backdrop-blur-sm'>
        <div className='w-full max-w-lg p-6 m-5 bg-white border rounded-md'>
          <div className='flex justify-between px-6 py-4 text-lg font-medium border-b'>
            <div className='text-blue-500'>Edit Version</div>
            <GrClose
              className='text-xl cursor-pointer '
              onClick={clickModalClose}
            />
          </div>

          <form onSubmit={handleSubmit}>
            <div className='flex flex-col gap-4 p-4'>
              <input
                type='text'
                value={name}
                placeholder='Name'
                onChange={(e) => setName(e.target.value)}
                className='p-2 border rounded'
                required
              />
              <input
                type='text'
                value={version}
                placeholder='Version'
                onChange={(e) => setVersion(e.target.value)}
                className='p-2 border rounded'
                required
              />
              <button
                type='submit'
                className='w-full text-white rounded text-lg px-6 py-1.5 focus:outline-none font-semibold whitespace-nowrap transition-colors bg-blue-500 hover:bg-blue-600'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AppVersionEditModal;
