import { AxiosResponse } from 'axios';
import { apiClient, apiDetailClient } from '../apiConfig';

export interface ITipPatchForm {
  id: string;
  block_yn: boolean;
  connected: string;
}

export const IPLAYER_EDIT_POST = async (data: ITipPatchForm) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      '/api/edit-user',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};

// export const IPLAYER_KICK_POST = async (id: string) => {
//   try {
//     const payload = {
//       id: id,
//     };
//     const response: AxiosResponse = await apiClient.post(
//       '/api/kick-user',
//       payload
//     );
//     return response;
//   } catch (err) {
//     return err;
//   }
// };

export const IPLAYER_KICK_POST = async (payload: {
  id: string;
  agent_id: string;
  user_id: string;
}) => {
  try {
    const response: AxiosResponse = await apiClient.post(
      '/api/kick-user',
      payload
    );
    return response;
  } catch (err) {
    return err;
  }
};
