import { AxiosResponse } from 'axios';
import { apiClient } from '../apiConfig';

export interface INoticeSearchForm {
  type: string;
  status: string;
}

export interface INoticeResponse {
  id: number;
  name: string;
  type: string;
  status: string;
  start: string;
  end: string;
  created_at: string;
  updated_at: string;
  contentKo: string;
  contentEn: string;
  contentCh: string;
  contentJp: string;
}

export interface INewNoticeResponse {
  application: string;
  title: string;
  kr?: string | null;
  en?: string | null;
  cn?: string | null;
  jp?: string | null;
  startDate?: string;
  endDate?: string;
  type?: string;
  status?: string;
}

export const NOTICE_GET_QUERY = async () => {
  try {
    const response: AxiosResponse = await apiClient.get('/notice');
    return response;
  } catch (err) {
    return err;
  }
};

export const NEW_NOTICE_GET_QUERY = async () => {
  try {
    const response: AxiosResponse = await apiClient.get(
      '/notice/get_gs_scroll_notice'
    );
    return response;
  } catch (err) {
    return err;
  }
};

export const NEW_SCROLL_NOTICE_GET = async () => {
  try {
    const response: AxiosResponse = await apiClient.get('maintenance/notice');
    // console.log('API Response:', response);
    // console.log('Response Data:', response.data.data);
    // console.log('Response Notices:', response.data.data.notice);
    return response;
  } catch (err) {
    console.error('Error fetching notices:', err);
    throw new Error('Failed to fetch notices');
  }
};
