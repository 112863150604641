import { AxiosResponse } from 'axios';
import { apiClient, apiDetailClient } from '../apiConfig';

export interface IVersionPost {
  version: string;
  name: string;
}

export const VERSION_POST = async (data: IVersionPost) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.post(
      'maintenance/version',
      payload
    );
    return response;

    // if (response.data && response.data.code === 0) {
    //   console.log(response.data.message);
    //   return response.data.message;
    // } else {
    //   throw new Error('Unexpected response structure');
    // }
  } catch (err) {
    console.error('Error adding Version:', err);
    throw err;
  }
};

export const VERSION_PATCH = async (data: IVersionPost) => {
  try {
    const payload = data;
    const response: AxiosResponse = await apiClient.patch(
      'maintenance/version',
      payload
    );
    return response;
  } catch (err) {
    console.error('Error updating Version:', err);
    throw err;
  }
};
