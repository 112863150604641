import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useForm, useFormState } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import {
  IBetHistoryAvaResponse,
  IBetHistoryAvaSearch,
  BET_HISTORY_AVA_POST_QUERY,
} from '../../../common/api/queries/bethistory_query';
import { JUNKET_GET_QUERY } from '../../../common/api/queries/junket_query';
import {
  ITableResponse,
  TABLES_GET_QUERY,
} from '../../../common/api/queries/table_query';

import Table from 'antd/es/table';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { ButtonCustom } from '../../../components/button';
import DatePicker from 'react-datepicker';
import {
  gameTableList,
  junketList,
  langVar,
  routeTitleVar,
} from '../../../common/apollo';
import { Button, Space } from 'antd';
import { SaGameBetUpdateModal } from './modal/saGameBetUpdateModal';
import SaGameBetList from './saGameBetList';
import { DateValidationAndConverter } from '../../../common/functions/dateValidationAndConverter';
import { betStatsCombi } from '../../../components/betComponents/betStatus-combination';

export interface TableParams {
  pagination: TablePaginationConfig;
}

export const SaGameBetSearch = () => {
  const { register, getValues, handleSubmit, control } =
    useForm<IBetHistoryAvaSearch>({
      mode: 'onChange',
    });
  const { isSubmitting } = useFormState({ control });
  const { t } = useTranslation(['page']);
  const [data, setData] = useState<IBetHistoryAvaResponse[]>([]);
  const [isHandlePage, setIsHandlePage] = useState(false);
  const [selectedJunket, setSelectedJunket] = useState<string>('');
  const [selectedGameTable, setSelectedGameTable] = useState<string>('');
  const [filteredGameTableList, setFilteredGameTableList] = useState<
    ITableResponse[]
  >([]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const selectedLang = useReactiveVar(langVar);
  const gameTable = useReactiveVar(gameTableList);
  const junketLists = useReactiveVar(junketList);

  const convertToEpochTime = (date: Date | null): number | null => {
    if (date === null) {
      return null;
    }
    const milliseconds = date.getTime();
    const seconds = Math.floor(milliseconds / 1000);

    return seconds;
  };

  // Junket & Table Number Validation
  useEffect(() => {
    if (selectedJunket) {
      const filteredTables = gameTable.filter(
        (table) => table.category_code === selectedJunket
      );
      setFilteredGameTableList(filteredTables);
    } else {
      setFilteredGameTableList(gameTable);
    }
  }, [selectedJunket, gameTable]);

  useEffect(() => {
    if (selectedGameTable) {
      const matchingJunket = gameTable.find(
        (table) => table.table_code === selectedGameTable
      );
      if (matchingJunket) {
        setSelectedJunket(matchingJunket.category_code);
      }
    }
  }, [selectedGameTable, gameTable]);
  // End Junket & Table Number Validation

  const onSubmit = async (isSearch: boolean) => {
    const { game_no, stats, proposal_num } = getValues();

    const { current: cur_page, pageSize: unit_page } =
      tableParams.pagination || {};

    const maxDateSearch = 14;
    const convertedDate = DateValidationAndConverter(
      startDate,
      endDate,
      maxDateSearch
    );

    if (convertedDate === 'Invalid Date Range') {
      alert(
        `Invalid Search Date Range, Only Accept ${maxDateSearch} Days Range`
      );
    } else if (convertedDate === 'Invalid Search') {
      alert('Search start time cannot later than end.');
    } else {
      const data = {
        cur_page: isSearch ? 1 : cur_page,
        unit_page: unit_page,
        category_code: selectedJunket,
        table_code: selectedGameTable,
        proposal_num: proposal_num,
        game_no: game_no,
        stats: stats,
        from_time: convertToEpochTime(startDate),
        to_time: convertToEpochTime(endDate),
      };

      await BET_HISTORY_AVA_POST_QUERY(data)
        .then((res: AxiosResponse | any) => {
          if (res.data) {
            if (res.data.data.code === 1) {
              setData(res.data.data.data.list);
              setTableParams((prevTableParams) => ({
                ...prevTableParams,
                pagination: {
                  ...prevTableParams.pagination,
                  current: isSearch ? 1 : prevTableParams.pagination?.current,
                  total: res.data.data.data.tot_page * Number(unit_page),
                },
              }));
            } else {
              setData([]);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setIsHandlePage(!isHandlePage);
    setTableParams((prevTableParams) => {
      const newTableParams = {
        ...prevTableParams,
        pagination,
      };

      if (pagination.pageSize !== prevTableParams.pagination?.pageSize) {
        setData([]);
      }

      return newTableParams;
    });
  };

  useEffect(() => {
    handleSubmit(() => onSubmit(false))();
  }, [isHandlePage]);

  useEffect(() => {
    routeTitleVar(String(t('Speed Game (Avatar)')));
  }, [selectedLang]);

  return (
    <>
      <div className='p-6 bg-white rounded-lg shadow-lg'>
        <form onSubmit={handleSubmit(() => onSubmit(true))}>
          <div className='flex flex-wrap items-center justify-end gap-3 mb-5'>
            <div className='flex flex-row justify-between w-full gap-3 sm:w-auto'>
              <div className='w-full form-group'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Junket')}
                </div>
                <select
                  className='forminput'
                  value={selectedJunket}
                  onChange={(e) => {
                    setSelectedJunket(e.target.value);
                    setSelectedGameTable('');
                  }}
                >
                  <option value=''>{t('Entire')}</option>
                  {junketLists.map((junket) => (
                    <option key={junket.id} value={junket.id}>
                      {junket.name.charAt(0).toUpperCase() +
                        junket.name.slice(1).toLowerCase()}
                    </option>
                  ))}
                </select>
              </div>

              <div className='w-full form-group'>
                <div className='mb-2 text-gray-500 whitespace-nowrap'>
                  {t('Table Number')}
                </div>
                <select
                  className='forminput'
                  value={selectedGameTable}
                  onChange={(e) => setSelectedGameTable(e.target.value)}
                >
                  <option value=''>{t('Entire')}</option>
                  {filteredGameTableList.map((table) => (
                    <option key={table.id} value={table.table_code}>
                      {table.table_code}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='flex flex-row w-full gap-3 sm:w-auto'>
              <div className='w-full form-group'>
                <div className='mb-2 text-gray-500'>{t('Proposal Number')}</div>
                <input
                  {...register('proposal_num')}
                  type='text'
                  className='forminput'
                  placeholder={String(t('Enter Proposal Number'))}
                />
              </div>
              <div className='w-full form-group'>
                <div className='mb-2 text-gray-500'>{t('Game Number')}</div>
                <input
                  {...register('game_no')}
                  type='text'
                  className='forminput'
                  placeholder={String(t('Enter Game Number'))}
                />
              </div>
            </div>

            <div className='flex flex-row items-center w-full pt-6 sm:w-auto md:flex-col md:items-start md:pt-0'>
              <span className='w-12 mb-2'>{t('From')}:</span>
              <div className='customDatePickerWidth'>
                <DatePicker
                  className='forminput pr-7'
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  isClearable
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={60}
                  timeCaption='Hour'
                  dateFormat={'yyyy/MM/dd HH:mm'}
                  placeholderText={String(t('Starting Point'))}
                />
              </div>
            </div>

            <div className='flex flex-row items-center w-full pt-6 sm:w-auto md:flex-col md:items-start md:pt-0'>
              <span className='w-12 mb-2'>{t('To')}:</span>
              <div className='customDatePickerWidth'>
                <DatePicker
                  className='forminput pr-7'
                  selected={endDate}
                  onChange={(date: Date) => setEndDate(date)}
                  isClearable
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={60}
                  timeCaption='Hour'
                  dateFormat={'yyyy/MM/dd HH:mm'}
                  placeholderText={String(t('End Point'))}
                />
              </div>
            </div>

            <div className='flex flex-row w-full gap-3 pt-6 sm:w-auto'>
              <div className='flex w-full sm:w-auto'>
                <select {...register('stats')} className='forminput'>
                  <option value={0}>{t('All')}</option>
                  <option value={1}>{t('After Settle')}</option>
                  <option value={2}>{t('Before Settle')}</option>
                </select>
              </div>

              <div className='flex'>
                <ButtonCustom
                  canClick={!isSubmitting}
                  actionText={t('Search')}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className='mt-5 border-l border-r rounded-md shadow-lg'>
        <div className='flex justify-between p-4 rounded-md'>
          <div>
            <h1 className='text-lg font-semibold '>
              {t('Speed Game (Avatar)')}
            </h1>
            {/* <p className='text-xs '>{t('Add & Edit')}</p> */}
          </div>
        </div>
        <SaGameBetList
          data={data}
          tableParams={tableParams}
          handleTableChange={handleTableChange}
          loading={isSubmitting}
        />
      </div>
    </>
  );
};
